import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import {Helmet} from "react-helmet";

export default function InstructionsPage() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          instructionsPage {
            contact1
            contact2
            content
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.instructionsPage;
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="max-w-3xl relative py-16 px-12">
        <div dangerouslySetInnerHTML={{__html: metadata.content}} />
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row justify-between">
          <div dangerouslySetInnerHTML={{__html: metadata.contact1}} />
          <div dangerouslySetInnerHTML={{__html: metadata.contact2}} />
        </div>
      </div>
    </main>
  );
}
